import React from "react"
import { useForm } from "react-hook-form"

// MUI components
import { styled } from "@mui/material/styles"
import Icon from "@mui/material/Icon"
import { useTheme } from "@mui/material/styles"

//Core components
import GridContainer from "components/material-dashboard-pro-react/components/Grid/GridContainer.js"
import { getIdentification, indentificationTypeAll } from "utils/utils"
import Identification from "components/Core/Controller/Identification"
import Button from "components/material-dashboard-pro-react/components/CustomButtons/Button.js"

const useStyles = (theme) => ({
    containerButton: {
        marginTop: '2em'
    },
    containerGrid: {
        padding: '1.5em'
    }
});

export default function IdentificationToPayPublic(props) {
  const defaultValues ={
    p_identification_type_1: '',
    p_identification_number_1: ''
  }
  const methods = useForm({defaultValues})
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    ...objForm
  } = methods
  const { handlePropsParams } = props
  const theme = useTheme()
  const styles = useStyles(theme)

  async function onSubmit(dataform, e) {
    const [numid, dvid] = getIdentification(
      dataform.p_identification_type_1,
      dataform.p_identification_number_1
    )
    const params = {
      p_identification_type: dataform.p_identification_type_1,
      p_identification_number: parseInt(numid),
      p_identification_id: `${dvid}`,
    }
    handlePropsParams(params)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <GridContainer justify="center" className={styles.containerGrid}>
        <GridContainer justify="center">
          <h3>Identificación del cliente</h3>
        </GridContainer>
        <GridContainer justify="center" xs={12} sm={6} md={5}>
          <Identification
            objForm={objForm}
            errors={errors}
            index={1}
            control={control}
            arrayType={indentificationTypeAll}
          />
          <GridContainer
            className={styles.containerButton}
            justify="center"
            xs={12}
          >
            <Button color="primary" type="submit">
              {" "}
              Siguiente <Icon>fast_forward</Icon>
            </Button>
          </GridContainer>
        </GridContainer>
      </GridContainer>
    </form>
  )
}
